import useSWR from "swr";
import { ConversationResponse } from "../types";
import { fetcher } from "./fetcher";
import { useAuth0 } from "@auth0/auth0-react";

export const useFetchConversations = () => {
  const { getAccessTokenSilently } = useAuth0();

  const {
    data: conversations,
    error: conversationsError,
    mutate,
  } = useSWR<ConversationResponse>(
    ["/conversations", getAccessTokenSilently],
    fetcher,
    {
      refreshInterval: 1000,
    }
  );

  return { conversations, conversationsError, mutate };
};
