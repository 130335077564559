import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuthenticatedFetch } from "../hooks/fetcher";
import { useFetchConversations } from "../hooks/useFetchConversations";

export const NewConversation = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const { mutate } = useFetchConversations();
  const fetch = useAuthenticatedFetch();
  const {
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    watch,
    register,
  } = useForm();
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const handleCategoryChange = (event: any) => {
    setSelectedCategory(event.target.value);
  };

  const onSubmit = async (formData: any) => {
    try {
      const { _id: contactId } = await fetch("/conversations", {
        method: "POST",
        body: formData,
      });

      await mutate();

      if (contactId) {
        navigate(`/chat/${contactId}`);
        reset();
        setOpen(false);
      }
    } catch (error) {
      setError("backend", {
        type: "server",
        message: "Something went wrong",
      });
    } finally {
      setLoading(false);
    }
  };

  const categories: any = [];

  const categoriesFeatureFlag = false;

  const required = !Boolean(watch("email")) && !Boolean(watch("whatsapp"));

  return (
    <>
      <Button
        size="large"
        fullWidth
        startIcon={<AddIcon />}
        onClick={handleClickOpen}
      >
        New conversation
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Add contact</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Here you can create a new contact and start a new conversation{" "}
            </DialogContentText>
            <TextField
              {
                ...register("email", {
                  required,
                }) /* TODO: #test, renaming email makes the backend save the wrong field. we need a test to check if we are sending the form right*/
              }
              error={!!errors.email}
              helperText={
                !!errors.email ? "Email or WhatsApp is required" : null
              }
              autoFocus
              margin="dense"
              id="email"
              label="Email address"
              type="email"
              fullWidth
              variant="standard"
            />
            <TextField
              {...register("whatsapp", { required })}
              error={!!errors.email}
              helperText={
                !!errors.email ? "Email or WhatsApp is required" : null
              }
              margin="dense"
              id="phone"
              label="WhatsApp"
              type="tel"
              fullWidth
              variant="standard"
            />

            {categoriesFeatureFlag && (
              <Grid container>
                <Grid item sm={selectedCategory === "new" ? 6 : 12}>
                  <FormControl margin="dense" fullWidth variant="standard">
                    <InputLabel id="demo-simple-select-standard-label">
                      Category
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={selectedCategory}
                      onChange={handleCategoryChange}
                      label="Category"
                    >
                      {categories.map((name: string) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                      <MenuItem key="new" value="new">
                        New
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {selectedCategory === "new" && (
                  <Grid item sm={6}>
                    <TextField
                      margin="dense"
                      id="name"
                      label="New category name"
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                )}
              </Grid>
            )}
            <TextField
              {...register("displayName")}
              helperText={"optional"}
              margin="dense"
              id="name"
              label="Display name"
              fullWidth
              variant="standard"
              sx={{ pb: 2 }}
            />
            {errors.backend && (
              <Alert severity="error">
                An error happened, check if contact already exists
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
              type="submit"
            >
              Create
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
