import React, { useEffect, useRef } from "react";
import useSWR from "swr";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import InstagramIcon from "@mui/icons-material/Instagram";
import {
  Grid,
  Box,
  IconButton,
  InputBase,
  Paper,
  Stack,
  Tooltip,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import { getBackendUrl } from "../hooks/fetcher";
import { ConversationItem, File } from "../types";
import { useFetchMessages } from "../hooks/useFetchMessages";
import { useFile, Media } from "./DropFile";
import fileIcon from "../assets/empty-file.png";
import data from "@emoji-mart/data";
import EmojiPicker from "@emoji-mart/react";
import VoiceRecorder from "../components/VoiceRecorder";

function useOnClickOutside(ref: any, handler: any) {
  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

export const useFetchTemplates = () => {
  const { data: templates, error: templateError } = useSWR<any>(
    ["/merchant"],
    async () => {
      return {
        list: [
          {
            header: "Sorry for the delay!",
            text: "We have to apologize as we were unable to answer your question on time. \nPlease let us know if we can still help - just reply to this message! Someone from the team will connect back with you within 2 hours, thank you!",
            name: "reach_out_after_24h_english",
            variables: [{ value: "contact_name" }],
          },
        ],
      };
    }
  );
  return { templates, templateError };
};

const RenderFile = ({
  file,
  remove,
}: {
  file: Media;
  remove: (fileId: string) => void;
}) => {
  const [hover, setHover] = React.useState(false);
  const toggleHover = () => setHover(!hover);

  const filePreview = () => {
    if (file.type === "image/jpeg")
      return <img height="80px" alt="hello" src={file.preview} />;
    if (file.type === "application/pdf")
      return (
        <embed src={file.preview} style={{ maxWidth: 70 }} height="80px" />
      );
    if (file.type === "video/mp4")
      return (
        <video height="80px" controls>
          <source src={file.preview} type={file.type} />
          <img alt="hello" src={fileIcon} />
        </video>
      );
    if (file.type === "audio/ogg")
      return (
        <audio
          src={file.preview}
          style={{
            minHeight: "40px",
            maxHeight: "40px",
            maxWidth: "100%",
          }}
          controls
        />
      );
    return <img height="80px" alt="hello" src={fileIcon} />;
  };

  return (
    <Tooltip title={file.name} arrow placement="top">
      <Box
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
        key={file.id}
        sx={{
          display: "inline-block",
          textAlign: "center",
          margin: "10px 10px 0 0",
          position: "relative",
        }}
      >
        {hover && (
          <Box sx={{ position: "absolute", zIndex: 3, top: 0, right: 0 }}>
            <HighlightOffIcon
              sx={{
                cursor: "pointer",
                background: "#fff",
                borderRadius: "50%",
              }}
              onClick={() => remove(file.id)}
            />
          </Box>
        )}
        {filePreview()}
      </Box>
    </Tooltip>
  );
};

export const MessageTextArea = ({
  children,
  contactSelected,
}: React.PropsWithChildren<{ contactSelected: ConversationItem }>) => {
  const [focused, setFocused] = React.useState(false);
  const [hovered, setHover] = React.useState(false);
  const [showEmojiPicker, setEmojiPicker] = React.useState(false);
  const emojiPickerRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(emojiPickerRef, () => setEmojiPicker(false));
  const { messages, mutate } = useFetchMessages({
    contactId: contactSelected._id,
  });
  const { templates } = useFetchTemplates();
  const { getAccessTokenSilently } = useAuth0();
  const { files, remove, openDialog, setFiles, upload } = useFile();
  const [messageError, setMessageSendError] = React.useState("");
  const [draftMessage, setDraftMessageRaw] = React.useState<{
    [key: string]: {
      body?: string;
      cc?: string;
      header?: string;
      channel?: string; // TODO: type possibilities of channel
    };
  }>({
    empty: {
      body: "",
    },
  });
  const currentDraft = draftMessage[contactSelected?._id];
  const isWhatsappFreeText =
    contactSelected.thirdPartyStatus.whatsapp.status === "FREE_TEXT";
  const needTemplate =
    contactSelected.thirdPartyStatus.whatsapp.status === "TEMPLATE";
  const hasWhatsapp = Boolean(contactSelected.whatsapp);
  const hasEmail = Boolean(contactSelected.email);
  const isWhatsapp = hasWhatsapp && currentDraft?.channel === "whatsapp";
  const isEmail =
    hasEmail && (currentDraft?.channel === "email" || !isWhatsapp);
  const hasTemplateSelected = hasWhatsapp && true;
  const selectedTemplate = templates?.list?.[0];
  const shouldShowAttachments =
    files.length > 0 && !(isWhatsapp && needTemplate);
  const toggleValue =
    currentDraft?.channel ||
    (isWhatsapp && "whatsapp") ||
    (isEmail && "email") ||
    "email";
  const templateText = selectedTemplate?.variables?.reduce(
    (prev: string, curr: { value: string }, index: number) => {
      if (curr.value === "contact_name") {
        return prev?.replace(`{{${index + 1}}}`, contactSelected.displayName);
      }
      return prev;
    },
    selectedTemplate?.text
  );
  const templateHeading = selectedTemplate?.header; // TODO: #test, if we change this variable to heading it should fail

  const controlEnter = (e: React.KeyboardEvent) =>
    (e.metaKey || e.ctrlKey) && e.which === 13;

  const clearDraftMessage = () => {
    //TODO: type event, message sent doesnt send event
    setDraftMessageRaw({
      ...draftMessage,
      [contactSelected?._id || "empty"]: {
        body: "",
        cc: "",
        header: "",
        channel:
          currentDraft?.channel ||
          (contactSelected?.whatsapp ? "whatsapp" : "email"),
      },
    });
  };
  const setDraftMessageBody = (event: any) => {
    //TODO: type event, message sent doesnt send event
    setDraftMessageRaw({
      ...draftMessage,
      [contactSelected?._id || "empty"]: {
        ...currentDraft,
        body: event?.target?.value || "",
      },
    });
  };

  const setDraftMessageChannel = (newValue: string) => {
    //TODO: type event
    setDraftMessageRaw({
      ...draftMessage,
      [contactSelected?._id || "empty"]: {
        ...currentDraft,
        channel: newValue,
      },
    });
  };

  const setDraftMessageHeader = (newValue: string) => {
    //TODO: type event
    setDraftMessageRaw({
      ...draftMessage,
      [contactSelected?._id || "empty"]: {
        ...currentDraft,
        header: newValue,
      },
    });
  };
  const setDraftMessageCC = (newValue: string) => {
    setDraftMessageRaw({
      ...draftMessage,
      [contactSelected?._id || "empty"]: {
        ...currentDraft,
        cc: newValue,
      },
    });
  };

  const sendMessageRemotely = async (contact: string) => {
    const template =
      (isWhatsapp && needTemplate && selectedTemplate?.name) || undefined;

    try {
      const accessToken = await getAccessTokenSilently();
      const requestBody = {
        channel: currentDraft?.channel,
        to: contact,
        body: template ? selectedTemplate.text : currentDraft.body || "",
        template,
        header: template ? selectedTemplate.header : currentDraft?.header || "",
        cc: isEmail && (currentDraft?.cc?.split(",") || []),
        files: files?.map((file) => ({
          //TODO: type file
          location: file.location,
          mimeType: file.type,
          lastModified: file.lastModified,
          filename: file.name,
        })),
      };

      await axios.post(getBackendUrl() + "/message", requestBody, {
        headers: {
          authorization: "Bearer " + accessToken,
        },
      });

      return messages;
    } catch (error) {
      if ((error as any).error === "login_required") {
        window.location.pathname = "/signin";
      }
      throw error;
    }
  };

  const sendMessage = async (contactId: string) => {
    const messageToBeSent = draftMessage[contactId].body;
    const template =
      (isWhatsapp && needTemplate && selectedTemplate?.name) || undefined;
    const filesToBeSent = files;

    setFiles([]);
    setMessageSendError("");
    clearDraftMessage();
    const messagesWithNew = [
      ...(messages || []),
      {
        channel: currentDraft?.channel || "email",
        _id: String(Math.random()),
        merchantId: "",
        files: filesToBeSent.length ? (filesToBeSent as File[]) : undefined,
        author: "",
        to: contactId,
        header: template ? selectedTemplate.header : currentDraft?.header || "",
        body:
          isWhatsapp && needTemplate && selectedTemplate
            ? selectedTemplate.text
            : messageToBeSent || "",
        direction: "outbound",
        type: "text",
      },
    ];

    const options = {
      optimisticData: messagesWithNew,
      rollbackOnError: true,
      revalidate: true,
    };

    // FIXME: #bug don't allow sending messages while uploading files
    mutate(() => sendMessageRemotely(contactId), options).catch((test) => {
      setMessageSendError(
        "Sorry, an error occured. The message could not be sent"
      );
      setFiles(filesToBeSent);
      setDraftMessageBody({ target: { value: messageToBeSent } });
    });
  };
  return (
    <Grid
      item
      container
      xs={12}
      component={Paper}
      elevation={1}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        padding: 2,
        outline:
          focused || hovered
            ? hovered && !focused
              ? "rgb(64 171 126 / 40%) solid 2px;"
              : "#40AB7E solid 2px;"
            : null,
      }}
    >
      <Grid
        item
        justifyContent="space-between"
        alignItems="space-between"
        xs={12}
      >
        {isEmail && (
          <>
            <TextField
              id="standard-basic"
              onChange={(event) => setDraftMessageHeader(event.target.value)}
              value={currentDraft?.header}
              fullWidth
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">subject:</InputAdornment>
                ),
              }}
            />
            <TextField
              id="standard-basic"
              fullWidth
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              onChange={(event) => setDraftMessageCC(event.target.value)}
              value={currentDraft?.cc}
              variant="standard"
              sx={{ margin: "10px 0 20px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">cc:</InputAdornment>
                ),
              }}
            />
          </>
        )}

        {isWhatsapp && needTemplate ? (
          <Tooltip
            title="The 24 hours window to reply expired. If you want to initiate a conversation now, WhatsApp only allows to send a WhatsApp Template Message that was approved by WhatsApp."
            arrow
            placement="top"
          >
            <Box sx={{ marginBottom: "8px", padding: "1px 0 5px" }}>
              {templateHeading && (
                <Typography style={{ fontWeight: 600 }}>
                  {templateHeading}
                </Typography>
              )}
              <Typography>{templateText}</Typography>
              {Boolean(files.length > 0) && (
                <Typography variant="caption">
                  WhatsApp template doesn't support attachments
                </Typography>
              )}
            </Box>
          </Tooltip>
        ) : (
          <InputBase
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            value={currentDraft?.body}
            placeholder={
              isWhatsappFreeText || isEmail
                ? "Type a message"
                : "Whatsapp messages can only be replyed in 24h"
            }
            id="validation-outlined-input"
            multiline
            size="small"
            onKeyDown={(e) => {
              if (controlEnter(e)) {
                sendMessage(contactSelected?._id || "empty");
              }
            }}
            fullWidth
            sx={{ mb: 1 }}
            maxRows={8}
            disabled={!(isWhatsappFreeText || isEmail)}
            onChange={setDraftMessageBody}
            error={Boolean(messageError)}
          />
        )}
      </Grid>
      {shouldShowAttachments && (
        <Grid columnSpacing={2} justifyContent="space-evenly" container>
          <Grid item xs={12}>
            <Box sx={{ display: "block", width: "100%", textAlign: "left" }}>
              {files?.map((file: Media) => (
                <RenderFile file={file} remove={remove} />
              ))}
            </Box>
          </Grid>
        </Grid>
      )}
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="space-between"
        xs={12}
        sx={{ position: "relative" }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <ToggleButtonGroup
            size="small"
            exclusive
            value={toggleValue}
            onChange={(_, newValue) => {
              setDraftMessageChannel(newValue);
            }}
            aria-label="channel"
          >
            <ToggleButton
              disabled={!hasWhatsapp}
              color="primary"
              value="whatsapp"
              aria-label="whatsapp"
            >
              <WhatsAppIcon />
            </ToggleButton>
            <ToggleButton
              disabled={!hasEmail}
              color="primary"
              value="email"
              aria-label="email"
            >
              <MailOutlineIcon />
            </ToggleButton>
            <ToggleButton
              color="primary"
              disabled
              value="instagram"
              aria-label="instagram"
            >
              <InstagramIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          {showEmojiPicker && (
            <div
              ref={emojiPickerRef}
              style={{ position: "absolute", top: -440, left: "17%" }}
            >
              <EmojiPicker
                data={data}
                onClickOutside={() => {
                  setEmojiPicker(false);
                }}
                onEmojiSelect={(emoji: { native: string }) => {
                  setDraftMessageBody({
                    target: {
                      value: (currentDraft?.body || "") + emoji.native,
                    },
                  });
                }}
              />
            </div>
          )}
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton disabled={!(isWhatsappFreeText || isEmail)}>
              <AttachFileIcon onClick={openDialog} />
            </IconButton>
            <IconButton
              sx={{ Left: "-10px" }}
              disabled={!(isWhatsappFreeText || isEmail) || showEmojiPicker}
            >
              <EmojiEmotionsIcon
                onClick={(event) => {
                  setEmojiPicker(true);
                }}
              />
            </IconButton>
            <VoiceRecorder onDataAvailable={upload} />
          </Stack>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <IconButton disabled={!(hasTemplateSelected || isEmail)}>
            <SendIcon
              onClick={() => {
                sendMessage(contactSelected?._id || "empty");
              }}
            />
          </IconButton>
        </Stack>
      </Grid>
    </Grid>
  );
};
