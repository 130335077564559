import * as React from "react";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Contact, ConversationItem } from "../types";
import parsePhoneNumber from "libphonenumber-js";

type Props = {
  contact: ConversationItem;
  onSetContact: (arg0: string) => void;
  contactSelected?: Contact;
  shownUnderCategory?: boolean;
};

export const Conversation = ({
  contact,
  onSetContact,
  contactSelected,
  shownUnderCategory,
}: Props) => {
  const phoneNumber = parsePhoneNumber("+" + contact.whatsapp);
  const lastMessage = contact.lastMessage;

  return (
    <>
      <Divider variant="inset" component="li" />
      <ListItem
        onClick={() => onSetContact(contact._id)}
        sx={{
          cursor: "pointer",
          marginLeft: shownUnderCategory ? "70px" : "0",
          bgcolor:
            contact._id === contactSelected?._id
              ? "action.selected"
              : "transparent",
        }}
        alignItems="flex-start"
      >
        <ListItemAvatar>
          <Avatar alt={contact.displayName} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <div
              style={{ fontWeight: contact.hasUnreadMessages ? "600" : "400" }}
            >
              {
                /*TODO: click on the phone to edit display name*/
                contact.displayName ||
                  contact.email ||
                  phoneNumber?.formatInternational()
              }
            </div>
          }
          secondary={
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {contact?.lastMessage?.header && (
                <Typography
                  sx={{
                    display: "inline",
                    fontWeight: contact.hasUnreadMessages ? "600" : "400",
                  }}
                  component="span"
                  noWrap
                  variant="body2"
                  color="text.primary"
                >
                  {contact.lastMessage.header}
                  <br />
                </Typography>
              )}
              <Typography
                component="span"
                noWrap
                sx={{
                  fontWeight: contact.hasUnreadMessages ? "600" : "400",
                }}
              >
                {lastMessage?.body}
              </Typography>
            </div>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};
