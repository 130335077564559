import { Grid, Paper } from "@mui/material";

export const ConversationsSection = ({ children }: React.PropsWithChildren) => (
  <Grid
    item
    xs={86}
    sm={93}
    md={26}
    component={Paper}
    elevation={3}
    square
    sx={{ height: "100%", overflow: "scroll" }}
  >
    {children}
  </Grid>
);
