import { Box, Grid } from "@mui/material";
import { DropFile } from "./DropFile";

export const MessagesSection = ({ children }: React.PropsWithChildren) => (
  <Grid item xs={100} md={70}>
    <DropFile>
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "grid",
          gridTemplateColumns: "1fr",
          gap: 0,
          gridTemplateRows: "50px 1fr",
        }}
      >
        {children}
      </Box>
    </DropFile>
  </Grid>
);
