import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

export const getBackendUrl = () => {
  if (window?.localStorage.getItem("backendUrl")) {
    return window?.localStorage.getItem("backendUrl");
  }
  return process.env.REACT_APP_BACKEND_URL;
};

export const fetcher = async (
  path: string,
  getToken: any,
  params?: { contact: string }
) => {
  try {
    const token = await getToken();
    const { data } = await axios.get(getBackendUrl() + path, {
      headers: { Authorization: "Bearer " + token },
      params,
    });
    return data;
  } catch (error) {
    if ((error as any).error === "login_required") {
      window.location.pathname = "/signin";
      return;
    }
    throw error;
  }
};

export const useAuthenticatedFetch = () => {
  //TODO: type function generic
  const { getAccessTokenSilently } = useAuth0();

  const _fetch = async (path: string, params: { [key: string]: any }) => {
    // TODO better type parameter
    const body =
      typeof params?.body?.append === "function"
        ? params.body
        : JSON.stringify(params.body);

    const contentType: any =
      typeof params?.body?.append === "function"
        ? {}
        : {
            Accept: "application/json",
            "Content-Type": "application/json",
          };

    try {
      const token = await getAccessTokenSilently();
      const data = await fetch(getBackendUrl() + path, {
        ...params,
        body,
        headers: {
          Authorization: "Bearer " + token,
          ...contentType,
        },
      }).then(function (response) {
        if (!response.ok) {
          throw new Error("Not 2xx response");
        } else {
          return response.json();
        }
      });

      return data;
    } catch (error) {
      if ((error as any).error === "login_required") {
        window.location.pathname = "/signin";
        return;
      }
      throw error;
    }
  };
  return _fetch;
};
