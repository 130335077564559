import { Message } from "./Message";
import { useFetchMessages } from "../hooks/useFetchMessages";
import { useAuthenticatedFetch } from "../hooks/fetcher";
import { ConversationItem } from "../types";
import React from "react";

type Props = {
  contactSelected: ConversationItem;
};

export const MessageList = ({ contactSelected }: Props) => {
  const lastMessageRef = React.useRef<HTMLDivElement>(null);
  const fetch = useAuthenticatedFetch();

  const { messages } = useFetchMessages({
    contactId: contactSelected?._id,
  });

  React.useEffect(() => {
    setTimeout(() => {
      lastMessageRef?.current?.scrollIntoView();
    }, 50);

    if (contactSelected.hasUnreadMessages) {
      fetch("/conversations/" + contactSelected?._id, {
        method: "PATCH",
        body: { hasUnreadMessages: false },
      });
    }
  }, [messages, fetch, contactSelected]);

  return (
    <>
      {messages && messages?.map((message) => <Message message={message} />)}
      <div id="last-message" ref={lastMessageRef} />
    </>
  );
};
