import { Box, Grid } from "@mui/material";

export const MessageFooter = ({ children }: React.PropsWithChildren) => {
  return (
    <Box sx={{ p: "13px 6%" }}>
      <Grid item container xs={12}>
        {children}
      </Grid>
    </Box>
  );
};
