import React from "react";
import Container from "@mui/material/Container";
import logo from "../assets/logo-black.png";
import { Link, Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";

function NewAccount() {
  return (
    <header className="App-header">
      <Paper elevation={3}>
        <Box sx={{ marginTop: 6, marginBottom: 6, textAlign: "center" }}>
          <Box component="img" mb={2} src={logo} width={280} alt="logo" />
          <Container component="main" maxWidth="xs">
            <Typography variant="h5" align="left" mt={1}>
              Welcome 👋
            </Typography>
            <Typography component="p" align="left" mb={1}>
              Thank you for signing up!
            </Typography>
            <Typography component="p" align="left">
              We are now activating your account, which might take some time. As
              soon as it is done, we'll reach out to you immediately 😊
            </Typography>
            <Typography component="p" align="left" mt={1}>
              In case you have any questions, feel free to 
              <Link href="mailto:support@getzimply.com">contact us</Link>, thank
              you!
            </Typography>
          </Container>
        </Box>
      </Paper>
    </header>
  );
}

export default NewAccount;
