import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { v4 as uuidv4 } from "uuid";
import { useAuthenticatedFetch } from "../hooks/fetcher";

export interface Media extends File {
  id: string;
  preview: string;
  location?: string;
}

const FileContext = React.createContext<{
  files: Media[];
  remove: (url: string) => void;
  openDialog: () => void;
  setFiles: (files: Media[] | []) => void;
  upload: (file: File[]) => void;
}>({
  files: [],
  remove: () => {},
  openDialog: () => {},
  setFiles: () => {},
  upload: () => {},
});

export const useFile = () => {
  const value = useContext(FileContext);
  return value;
};

export const DropFile = ({ children }: React.PropsWithChildren) => {
  const [files, setFiles] = useState<Media[]>([]);
  const fetch = useAuthenticatedFetch();

  const upload = useCallback(
    async (selectedFile: Media) => {
      const formData = new FormData();
      formData.append("file", selectedFile);
      console.log(selectedFile);

      try {
        setFiles((oldList) => {
          return oldList.map((file) => {
            if (file.id === selectedFile.id)
              return Object.assign(file, {
                loading: true,
              });
            return file;
          });
        });

        const { path } = await fetch("/media", {
          method: "POST",
          body: formData,
        });

        setFiles((oldList) => {
          return oldList.map((file) => {
            if (file.id === selectedFile.id)
              return Object.assign(file, {
                preview: path,
                location: path,
                loading: false,
              });
            return file;
          });
        });
      } catch (error) {
        console.log(error);
        setFiles((oldList) => {
          return oldList.map((file) => {
            if (file.id === selectedFile.id)
              return Object.assign(file, {
                error: true,
                loading: false,
              });
            return file;
          });
        });
      }
    },
    [fetch]
  );

  const remove = useCallback(
    (fileUrlToRemove: string) => {
      const withoutRemoved = files.filter(
        (file) => file.id !== fileUrlToRemove
      );
      setFiles(withoutRemoved);
    },
    [files]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const receivedFiles = acceptedFiles.map((file: File) => {
        const url = URL.createObjectURL(file);
        const fileWithId = Object.assign(file, {
          preview: url,
          id: uuidv4(),
        });
        upload(fileWithId);
        return fileWithId;
      });
      setFiles([...files, ...receivedFiles]);
    },
    [files, upload]
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
  });

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <div {...getRootProps({ onClick: (e) => e.stopPropagation() })}>
      <input {...getInputProps()} />
      <FileContext.Provider
        value={{ files, remove, setFiles, openDialog: open, upload: onDrop }}
      >
        <Box sx={{ position: "relative" }}>
          {isDragActive && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                fontSize: "90px",
                textAlign: "center",
                paddindTop: "40%",
                backgroundColor: "#000000d9",
                zIndex: 1,
                transition: "background-color 300ms",
              }}
            >
              <CloudUploadIcon fontSize="large" />
            </Box>
          )}

          {children}
        </Box>
      </FileContext.Provider>
    </div>
  );
};
