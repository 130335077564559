import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import logo from "../triforce-512.webp";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

function AuthCallback() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();
  useEffect(() => {
    console.log({ user, isAuthenticated, isLoading });
    if (isAuthenticated) {
      navigate(`/chat`);
    }
  }, [isAuthenticated, navigate, isLoading, user]);
  return (
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <br />
      <br />
      <Container component="main" maxWidth="xs">
        {isLoading && "Loading..."}
        {!isLoading &&
          !isAuthenticated &&
          "We are sorry, an error occured, please contact us"}
      </Container>
    </header>
  );
}

export default AuthCallback;
