import * as React from "react";
import Typography from "@mui/material/Typography";
import root from "react-shadow";
import { Grid, Paper, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import { SingleMessage } from "../types";
import Linkify from "linkify-react";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import ArticleIcon from "@mui/icons-material/Article";
import { useWindowWidth } from "@react-hook/window-size";

const fileExtension = (filename: string = "") =>
  filename.split(".").pop() || "";

const Item = styled(Paper)(({ theme }) => {
  return {
    textAlign: "left",
    display: "inline-block",
    minWidth: "10%",
    maxWidth: "100%",
    padding: theme.spacing(),
  };
});

type Props = {
  message: SingleMessage;
};

const renderLink = ({ attributes, content }: any) => {
  const { href, ...props } = attributes;
  return (
    <Link href={href} {...props} target="_blank">
      {content}
    </Link>
  );
};

const Attachment = ({ file }: any) => {
  const filePreview = (file: any) => {
    const extension = fileExtension(file?.filename);
    if (file.mimeType === "image/jpeg")
      return <img style={{ width: "100%" }} alt="hello" src={file.location} />;
    if (file.mimeType === "application/pdf")
      return <PictureAsPdfIcon fontSize="large" />;
    if (["mp4"].includes(extension)) return <VideoFileIcon fontSize="large" />;
    if (["ogg", "mp3"].includes(extension))
      return <AudioFileIcon fontSize="large" />;
    if (["doc", "docx"].includes(extension))
      return <ArticleIcon fontSize="large" />;
    return <InsertDriveFileIcon fontSize="large" />;
  };
  return (
    <Link
      href={file.location}
      download={file.filename}
      target="_blank"
      rel="noreferrer"
    >
      <div
        style={{
          display: "inline-block",
          border: "1px solid #b8b8b8",
          borderRadius: "5px",
          height: "120px",
          width: "200px",
          marginRight: "15px",
          position: "relative",
          overflow: "hidden",
          textAlign: "center",
        }}
      >
        <div
          style={{
            paddingBottom: "25px",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {filePreview(file)}
        </div>
        <Typography
          style={{
            display: "block",
            bottom: 0,
            position: "absolute",
            width: "100%",
            backgroundColor: "#f3f3f3",
            borderTop: "1px solid #b8b8b8",
            textAlign: "left",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            padding: "0px 13px 0 15px",
          }}
        >
          {file.filename || " "}
        </Typography>
      </div>
    </Link>
  );
};

const RenderAttachment = ({ files }: { files?: SingleMessage["files"] }) => {
  if (Boolean(files?.length))
    return (
      <div>
        - <br />
        Attachments: <br />
        <div>
          {files?.map((file) => (
            <Attachment file={file} />
          ))}
        </div>
      </div>
    );

  return null;
};

export const Message = ({ message }: Props) => {
  const ref = React.useRef<any>(null);
  const windowWidth = useWindowWidth();
  const mobile = windowWidth < 770;
  const [height, setHeight] = React.useState("1000px");
  const [width, setWidth] = React.useState("100%");
  const onLoad = () => {
    setHeight(
      ref?.current?.contentWindow?.document.body.scrollHeight + 20 + "px"
    );
    setWidth(
      ref?.current?.contentWindow?.document.body.scrollWidth + 20 + "px"
    );
  };
  const video = message?.type === "video";
  const audio = message?.type === "audio";
  const image = message?.type === "image";
  const html = message?.type === "html";
  const document = message?.type === "document";
  const text = message?.type === "text" || message?.type === undefined;

  return (
    <Grid
      container
      item
      sx={{
        maxWidth: mobile ? "90%" : "56vw",
        wordBreak: "break-word",
        minWidth: "45vw",
      }}
    >
      {message.direction === "outbound" && <Grid item xs={1} />}
      <Grid
        item
        xs={11}
        sx={{
          textAlign: message.direction === "outbound" ? "right" : "left",
        }}
      >
        <Item elevation={2}>
          {message.header && (
            <Typography gutterBottom sx={{ fontWeight: "bold" }}>
              {message.header}
            </Typography>
          )}
          <Typography>
            {html && (
              <>
                <root.div>
                  <iframe
                    title="test"
                    ref={ref}
                    height={height}
                    width={width}
                    onLoad={onLoad}
                    srcDoc={message.html}
                    scrolling="no"
                    frameBorder="0"
                  />
                </root.div>
                <RenderAttachment files={message.files} />
              </>
            )}
            {image && (
              <>
                <img
                  src={message.body || message?.files?.[0].location}
                  style={{
                    maxHeight: "390px",
                    maxWidth: "100%",
                  }}
                  alt="media received"
                />
                <Typography gutterBottom>
                  {message?.files?.[0]?.caption}
                </Typography>
              </>
            )}
            {document && (
              <>
                {message?.files?.[0].mimeType === "application/pdf" && (
                  <embed
                    src={message?.files?.[0].location}
                    style={{
                      minHeight: "500px",
                      maxHeight: "500px",
                      maxWidth: "100%",
                    }}
                  />
                )}
                {message?.files?.[0].mimeType?.includes("word") && (
                  <Attachment file={message?.files?.[0]} />
                )}
                <Typography gutterBottom>
                  {message?.files?.[0]?.caption}
                </Typography>
              </>
            )}
            {video && (
              <>
                <video
                  src={message.body || message?.files?.[0].location}
                  style={{
                    minHeight: "500px",
                    maxHeight: "500px",
                    maxWidth: "100%",
                  }}
                  controls
                />
                <Typography gutterBottom>
                  {message?.files?.[0]?.caption}
                </Typography>
              </>
            )}
            {audio && (
              <audio
                src={message.body || message?.files?.[0].location}
                style={{
                  minHeight: "40px",
                  maxHeight: "40px",
                  maxWidth: "100%",
                }}
                controls
              />
            )}
            {text && (
              <>
                <Linkify options={{ render: renderLink }}>
                  {message.body}
                </Linkify>
                <RenderAttachment files={message.files} />
              </>
            )}
          </Typography>
          <Typography variant="caption">
            {message.direction === "inbound" ? "Received" : "Sent"} via{" "}
            {message.channel === "whatsapp" && "WhatsApp"}
            {message.channel === "email" && "Email"}
          </Typography>
        </Item>
      </Grid>
      {message.direction === "inbound" && <Grid item xs={1} />}
    </Grid>
  );
};
