import { Box, Grid, Typography } from "@mui/material";
import parsePhoneNumber from "libphonenumber-js";
import { ConversationItem } from "../types";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

export const MessageHeader = ({
  children,
  contactSelected,
}: React.PropsWithChildren<{ contactSelected: ConversationItem }>) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const phoneNumber = parsePhoneNumber("+" + contactSelected?.whatsapp);
  return (
    <Box sx={{ width: "100%", textAlign: "center", paddingTop: "12px" }}>
      <Grid container>
        {mobile && (
          <Grid item xs={2} md={0} lg={0}>
            <KeyboardBackspaceIcon onClick={() => navigate(-1)} />
          </Grid>
        )}
        <Grid item xs={8} md={12}>
          <Typography>
            {
              /*TODO: click on the phone to edit display name*/
              contactSelected?.displayName ||
                contactSelected?.email ||
                phoneNumber?.formatInternational()
            }
          </Typography>
        </Grid>
        <Grid item xs={2} md={0}></Grid>
      </Grid>
    </Box>
  );
};
