import { ExpandLess, ExpandMore } from "@mui/icons-material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import {
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import List from "@mui/material/List";
import React from "react";
import { Conversation } from "../components/Conversation";
import {
  ConversationResponse,
  ConversationItem,
  ConversationCategory,
} from "../types";
import useLocalStorageState from "use-local-storage-state";

type Props = {
  conversations?: ConversationResponse;
  contactSelected?: ConversationItem;
  setContact: (arg0: string) => void;
};

export const ContactList = ({
  conversations,
  setContact,
  contactSelected,
}: Props) => {
  const [openFolder, setOpenFolder] = useLocalStorageState<{
    [key: string]: boolean;
  }>("contactCategories-ui-state", {});

  const unreadsQuantityByCategory = (category: ConversationCategory) => {
    return category?.contacts?.reduce((current, next) => {
      if (next.hasUnreadMessages) current++;
      return current;
    }, 0);
  };

  return (
    <List sx={{ width: "100%", paddingTop: 0 }}>
      {conversations?.byCategory?.map((category) => {
        const quantityOfUnreadMessages = unreadsQuantityByCategory(category);
        const hasUnreadMessages = quantityOfUnreadMessages > 0;
        return (
          <>
            <ListItemButton
              onClick={() =>
                setOpenFolder({
                  ...openFolder,
                  [category.name]: !openFolder?.[category.name],
                })
              }
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  sx: {
                    fontWeight: hasUnreadMessages ? "600" : "400",
                  },
                }}
                primary={
                  category.name +
                  (hasUnreadMessages
                    ? " (" +
                      (quantityOfUnreadMessages === 1
                        ? "1 unread"
                        : quantityOfUnreadMessages + " unreads") +
                      ")"
                    : "")
                }
              />
              {openFolder?.[category.name] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse
              in={openFolder?.[category.name]}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {category?.contacts?.map((contact) => (
                  <Conversation
                    contactSelected={contactSelected}
                    onSetContact={setContact}
                    contact={contact}
                    shownUnderCategory
                  />
                ))}
              </List>
            </Collapse>
          </>
        );
      })}
      {conversations?.list?.map((contact) => {
        if (contact?.category) return null;
        return (
          <Conversation
            contactSelected={contactSelected}
            onSetContact={setContact}
            contact={contact}
          />
        );
      })}
    </List>
  );
};
