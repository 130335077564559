import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import logo from "../triforce-512.webp";
import { useAuth0 } from "@auth0/auth0-react";

function LogOut() {
  const { logout } = useAuth0();
  useEffect(() => {
    logout({ returnTo: window.location.origin + "/signin" });
  }, [logout]);
  return (
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <br />
      <br />
      <Container component="main" maxWidth="xs">
        Loading...
      </Container>
    </header>
  );
}

export default LogOut;
