import { IconButton } from "@mui/material";
import { red } from "@mui/material/colors";
import MicNoneIcon from "@mui/icons-material/MicNone";
import SettingsVoiceIcon from "@mui/icons-material/SettingsVoice";
import { useState } from "react";
import MediaRecorder from "opus-media-recorder";

// opus-media-recorder options
const workerOptions = {
  encoderWorkerFactory: function () {
    return new Worker(
      process.env.PUBLIC_URL + "/opus-media-recorder/encoderWorker.umd.js"
    );
  },
  OggOpusEncoderWasmPath:
    process.env.PUBLIC_URL + "/opus-media-recorder/OggOpusEncoder.wasm",
  WebMOpusEncoderWasmPath:
    process.env.PUBLIC_URL + "/opus-media-recorder/WebMOpusEncoder.wasm",
};

export interface Media extends File {
  id: string;
  preview: string;
  location?: string;
}

interface RecorderProps {
  fileName?: string;
  onDataAvailable: (file: File[]) => void;
}

let myInterval: any = null;
let recorder: any = null;
let stream: any = null;

export const RecorderTimer = ({ onDataAvailable }: RecorderProps) => {
  const [seconds, setSeconds] = useState(0);
  const [isRecording, setIsRecording] = useState(false);

  const askPermission = async () => {
    stream = await (navigator as any).mediaDevices.getUserMedia({
      audio: true,
    });
    recorder = new (MediaRecorder as any)(
      stream,
      { mimeType: "audio/ogg" },
      workerOptions
    );
    recorder.addEventListener("dataavailable", (e: any) => {
      console.log("Recording stopped, data available");
      const name = Date.now() + ".ogg";
      const file = new File([e.data], name, { type: "audio/ogg" });

      onDataAvailable([
        Object.assign(file, {
          path: name,
        }),
      ]);
    });
    recorder.addEventListener("start", () => {
      console.log("start");
    });
    recorder.addEventListener("stop", () => {
      console.log("stop");
    });
    recorder.addEventListener("pause", () => {
      console.log("pause");
    });
    recorder.addEventListener("resume", () => {
      console.log("resume");
    });
    recorder.addEventListener("error", (e: any) => {
      console.log("error", e);
    });
  };
  const resetTimer = () => {
    clearInterval(myInterval);
    setSeconds(0);
  };
  const triggerTimer = async () => {
    myInterval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);
  };
  const startRecording = async () => {
    await askPermission();

    setIsRecording(true);
    await triggerTimer();
    recorder.start();
  };
  const stopRecording = async () => {
    setIsRecording(false);
    recorder.stop();
    stream.getTracks().forEach(function (track: any) {
      track.stop();
    });
    resetTimer();
  };
  return (
    <div>
      <IconButton onClick={isRecording ? stopRecording : startRecording}>
        {!isRecording && <MicNoneIcon />}
        {isRecording && <SettingsVoiceIcon sx={{ color: red[500] }} />}
      </IconButton>
      {isRecording && <span>{seconds}s</span>}
    </div>
  );
};

export default RecorderTimer;
