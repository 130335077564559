import { Grid, Box } from "@mui/material";

export const MessageBody = ({ children }: React.PropsWithChildren) => {
  return (
    <Box sx={{ width: "100%", overflow: "scroll" }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        rowSpacing={2}
        sx={{ margin: "0 0 30px" }}
      >
        {children}
      </Grid>
    </Box>
  );
};
