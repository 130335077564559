import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import Chat from "./routes/chat";
import SignIn from "./routes/signin";
import NewAccount from "./routes/NewAccount";
import AuthCallback from "./routes/auth-callback";
import Logout from "./routes/logout";

const theme = createTheme({
  palette: {
    primary: {
      main: "#40AB7E",
    },
    secondary: {
      main: "#6596EA",
    },
    background: {
      default: "#E5E5E5",
    },
  },
  typography: {
    fontFamily: [
      "Roboto",
      "Noto Color Emoji",
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI",
      "Oxygen",
    ].join(","),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/chat/:selectedContact" element={<Chat />} />
          <Route path="/auth-callback" element={<AuthCallback />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/new-account" element={<NewAccount />} />
          <Route path="/" element={<Navigate to="/chat" />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
