import useSWR from "swr";
import { Messages } from "../types";
import { fetcher } from "./fetcher";
import { useAuth0 } from "@auth0/auth0-react";

export const useFetchMessages = ({ contactId }: { contactId?: string }) => {
  const { getAccessTokenSilently } = useAuth0();

  const {
    data: messages,
    error: messagesError,
    mutate,
  } = useSWR<Messages>(
    contactId
      ? ["/message", getAccessTokenSilently, { contact: contactId }] //TODO: refactor contact to contact id
      : null,
    fetcher,
    { refreshInterval: 3000 }
  );
  return { messages, messagesError, mutate };
};
