import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFetchConversations } from "../hooks/useFetchConversations";
import { Main } from "../components/Main";
import { ConversationsSection } from "../components/ConversationsSection";
import { NewConversation } from "../components/NewConversation";
import logo from "../assets/logo.png";
import LogoutIcon from "@mui/icons-material/Logout";
import { ContactList } from "../components/ContactList";
import { MessagesSection } from "../components/MessagesSection";
import { MessageHeader } from "../components/MessageHeader";
import { MessageBody } from "../components/MessageBody";
import { MessageList } from "../components/MessageList";
import { MessageFooter } from "../components/MessageFooter";
import { MessageTextArea } from "../components/MessageTextArea";
import { Grid, Link, Paper } from "@mui/material";
import { useWindowWidth } from "@react-hook/window-size";

const Chat = () => {
  const { conversations } = useFetchConversations();
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();
  const mobile = windowWidth < 770;
  const desktop = windowWidth >= 770;

  React.useEffect(() => {
    if (conversations?.message === "new_account") {
      navigate(`/new-account`);
    }
  }, [conversations, navigate]);

  const { selectedContact } = useParams();
  const contactSelected = conversations?.list?.find(
    (contact) => contact._id === selectedContact
  );

  const setContact = (contact: string) => {
    navigate(`/chat/${contact}`);
  };
  if (!selectedContact && conversations?.list.length && !mobile) {
    setContact(conversations.list[0]._id);
    return null;
  }

  return (
    <Main>
      {(desktop || (mobile && !contactSelected)) && (
        <>
          <Grid
            item
            xs={14}
            sm={7}
            md={4}
            component={Paper}
            elevation={3}
            square
          >
            <Grid
              container
              xs={12}
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <Grid item xs={11}>
                <img
                  src={logo}
                  style={{
                    width: "100%",
                    paddingTop: 20,
                    paddingLeft: 15,
                    paddingRight: 15,
                  }}
                  alt="company logo"
                />
              </Grid>
              <Grid item xs={1}>
                <Link href="/logout">
                  <LogoutIcon />
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <ConversationsSection>
            <NewConversation />
            <ContactList
              setContact={setContact}
              contactSelected={contactSelected}
              conversations={conversations}
            />
          </ConversationsSection>
        </>
      )}
      {(desktop || (mobile && contactSelected)) && (
        <MessagesSection>
          {contactSelected && (
            <>
              <MessageHeader contactSelected={contactSelected} />
              <MessageBody>
                <MessageList contactSelected={contactSelected} />
              </MessageBody>
              <MessageFooter>
                <MessageTextArea contactSelected={contactSelected} />
              </MessageFooter>
            </>
          )}
        </MessagesSection>
      )}
    </Main>
  );
};

export default Chat;
